import React from 'react'

import { useMediaQuery } from 'react-responsive'
import formatDate from 'utils/formatDate'

import css from './MiniTrack.module.scss'

const Track = ({ goal, program, date }) => {
  const newDate = formatDate(date)
  const isMobile = useMediaQuery({ query: '(max-width: 650px)' })

  return (
    <div className={css.media}>
      <p>{goal && `${goal} →`} {program} {isMobile && <br />}<span>{newDate}</span></p>
    </div>
  )
}

export default Track
