import React, { useEffect, useState } from "react"
import { navigate } from "@reach/router"

import { useStore } from "effector-react"

import classes from "utils/classes"

import isEqual from "lodash/isEqual"

import { useTranslation } from "react-i18next"

import useGoals from "hooks/useGoals"
import usePrograms from "hooks/usePrograms"
import useFormats from "hooks/useFormats"
import useContents from "hooks/useContents"

import articlesStore from "state/articlesStore"

import withBlackHeader from "HOC/withBlackHeader"

import ArticlesList from "./ArticlesList/ArticlesList"
import Subscription from "./Subscription/Subscription"
import Menu from "./Menu/Menu"
import Seo from "components/seo"

import {
  filteredStore,
  setFiltered,
  filterStore,
  setFilter,
} from "./filterState"

import css from "./PageArticles.module.scss"

const PageArticles = ({ page = 1, articles, location }) => {
  const {
    i18n: { language },
  } = useTranslation()

  const filtered = useStore(filteredStore)
  const filter = useStore(filterStore)

  const [inited, setInited] = useState(false)

  const goals = useGoals()
  const programs = usePrograms()
  const formats = useFormats()
  const { newsTitle } = useContents()

  const articlesFront = useStore(articlesStore.store)
  const articlesList = filtered ? articlesFront : articles

  useEffect(() => {
    setInited(true)
  }, [])

  useEffect(() => {
    if (parseInt(page) === 1 || !inited) return

    navigate(`/${language}/news/page/1/`)
  }, [filter])

  useEffect(() => {
    const objFilter = {
      forceNoClear: true,
      [`translated_${language}`]: true,
      ...filter,
      page,
    }

    articlesStore.fetchEffect(objFilter)
  }, [filter, page])

  useEffect(() => {
    if (inited) setFiltered(true)
  }, [filter])

  return (
    <>
      <Seo
        title={articles.metaTitle}
        description={articles.metaDescription}
        keywords={articles.metaKeywords}
      />
      <div
        className={classes(
          css.container,
          filtered && articlesFront.isLoading && css.loading
        )}
      >
        <Menu
          goals={goals}
          articles={articlesList}
          programs={programs}
          formats={formats}
          initial={location?.state?.filter}
          cb={value => {
            if (!isEqual(value, filter)) setFilter(value)
          }}
        />
        <ArticlesList goals={goals} articles={articlesList} page={page} />
      </div>
      <Subscription grey />
    </>
  )
}

export default withBlackHeader(PageArticles)
