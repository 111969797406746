import React from "react"

import { navigate } from "gatsby"

import PageArticles from "components/PageArticles/PageArticles"

import Layout from "components/Layout/Layout"

import useChangeLanguage from "hooks/useChangeLanguage"

const News = ({ pageContext, location }) => {
  const { page, articles, lang } = pageContext
  useChangeLanguage(lang)
  if (!pageContext || !pageContext.page) {
    navigate("page/1/")
    return null
  }
  return (
    <Layout>
      <PageArticles page={page} articles={articles} location={location} />
    </Layout>
  )
}

export default News
