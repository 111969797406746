import { getArticles } from '../api'

import generator from './fabrics/list'

const articlesFabric = generator({
  title: 'articles',
  fetch: getArticles,
  fieldsToTranslate: [
    'title',
    'content',
    'pdf',
    'metaDescription',
    'metaKeywords',
    'metaTitle',
    'metaImage',

    { key: 'program', fieldsToTranslate: [
        'title',
        { key: 'goal', fieldsToTranslate: ['name'] }
      ]
    }
  ],
  rootFieldsToTranslate: ['metaTitle', 'metaDescription', 'metaKeywords'],
})

export default articlesFabric

export const fetchArticlesEffect = articlesFabric.fetchEffect
export const articlesStore = articlesFabric.store
