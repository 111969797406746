import { createStore, createEvent } from 'effector'

const setYear = createEvent()
const yearStore = createStore(null)
  .on(setYear, (state, payload) => payload)

const setFilterGoals = createEvent()
const filterGoalsStore = createStore([])
  .on(setFilterGoals, (state, payload) => payload)

const setFilterPrograms = createEvent()
const programsListStore = createStore([])
  .on(setFilterPrograms, (state, payload) => payload)

const setFilterFormats = createEvent()
const filterFormatsStore = createStore([])
  .on(setFilterFormats, (state, payload) => payload)

const setFiltered = createEvent()
const filteredStore = createStore(false)
  .on(setFiltered, (state, payload) => payload)

const setFilter = createEvent()
const filterStore = createStore({})
  .on(setFilter, (state, payload) => payload)

export {
  yearStore,
  setYear,

  filterGoalsStore,
  setFilterGoals,

  programsListStore,
  setFilterPrograms,

  filterFormatsStore,
  setFilterFormats,

  filteredStore,
  setFiltered,

  filterStore,
  setFilter,
}

