import { useStaticQuery, graphql } from 'gatsby'

import { useTranslation } from 'react-i18next'

export const useFormats = () => {
  const { i18n: { language } } = useTranslation()

  const { formatsRu, formatsEn } = useStaticQuery(
    graphql`
      query FormatsQuery {
        formatsRu {
          results {
            id
            title
          }
        }
        formatsEn {
          results {
            id
            title
          }
        }
      }
    `
  )

  if (language === 'en') return formatsEn
  return formatsRu
}

export default useFormats
