import React from 'react'
import { Link } from 'gatsby'
import { useMediaQuery } from 'react-responsive'

import useUrlPrefix from 'hooks/useUrlPrefix'

import MiniTrack from 'common/MiniTrack/MiniTrack'
import Arrow from 'common/Arrow/Arrow'
import Pagination from 'components/common/Pagination/Pagination'

import css from './ArticlesList.module.scss'

const ArticlesList = ({ page, articles }) => {
  let arrArticles = articles.results

  const urlPrefix = useUrlPrefix()

  const isMobile = useMediaQuery({ query: '(max-width: 650px)' })

  return (
    <>
      <div className={css.container}>
        <div className={css.content}>
          {arrArticles.map(item =>
            <Link to={urlPrefix(`/news/${item.slug}`)} className={css.blockContent} key={`${item.slug} ${Math.random()}`}>
              <MiniTrack
                goal={item.program && item.program.goal ? item.program.goal.name : null}
                program={item.program ? item.program.title : null}
                date={item.pubDate}
              />
              <h3>{item.title}</h3>

              <div className={css.blockArrow}>
                <Arrow
                  color={'orange'}
                  size={isMobile ? 'large' : 'big'}
                  className={css.arrow}
                />
              </div>
            </Link>)}
        </div>
        {articles.pagesCount > 1 && <Pagination
          basePath={urlPrefix('/news')}
          pagesCount={articles.pagesCount}
          page={parseInt(page)}
          countToShow={isMobile ? 1 : 3}
        />}
      </div>
    </>
  )}

export default ArticlesList
