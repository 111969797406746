import React, { useEffect, useState } from "react"
import css from "./Menu.module.scss"

import useContents from "hooks/useContents"

import classes from "utils/classes"
import pluralize from "utils/pluralize"

import filterCallback from "utils/filterCallback"

import { useTranslation } from "react-i18next"
import { useMediaQuery } from "react-responsive"
import { useStore } from "effector-react"

import {
  yearStore,
  setYear,
  filterGoalsStore,
  setFilterGoals,
  programsListStore,
  setFilterPrograms,
  filterFormatsStore,
  setFilterFormats,
} from "../filterState"

import MenuButton from "components/common/MenuButton/MenuButton"
import SelectButton from "components/common/SelectButton/SelectButton"
import Accordion from "components/common/Accordion/Accordion"
import PopupFilters from "components/common/PopupFilters/PopupFilters"

const Menu = ({ goals, articles, programs, formats, cb, initial }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const contents = useContents()

  const year = useStore(yearStore)
  const filterGoals = useStore(filterGoalsStore)
  const filterPrograms = useStore(programsListStore)
  const filterFormats = useStore(filterFormatsStore)

  const [programsList, setPrograms] = useState([])

  useEffect(() => {
    if (initial?.goal) setFilterGoals([initial.goal])
    if (initial?.program) setFilterPrograms([initial.program])
  }, [initial])

  let arrGoals = goals.results
  let activeGoals =
    filterGoals.length === 0
      ? arrGoals
      : filterGoals.map(id => arrGoals.find(goal => goal.id === id))
  const goalsActive = filterGoals.length > 0

  const getPrograms = () =>
    goalsActive
      ? programs.results.filter(
          item =>
            item.goal && activeGoals.map(({ id }) => id).includes(item.goal.id)
        )
      : programs.results

  const checkPrograms = () => {
    const possiblePrograms = getPrograms().map(program => program.id)
    const newFilter = programsListStore
      .getState()
      .filter(id => possiblePrograms.includes(id))
    setFilterPrograms(newFilter)
  }

  useEffect(() => {
    setPrograms(getPrograms())
    checkPrograms()
  }, [filterGoals, goals, programs])

  const callbackGoals = filterCallback(filterGoals, setFilterGoals)
  const callbackPrograms = filterCallback(filterPrograms, setFilterPrograms)
  const callbackFormats = filterCallback(filterFormats, setFilterFormats)

  useEffect(() => {
    if (!cb) return

    const filter = {}
    if (year) filter["pub_date__year"] = year
    if (filterGoals.length > 0)
      filter["programs__goal_id__or__program__goal_id__in"] = filterGoals.join(
        ","
      )
    // if (filterPrograms.length > 0) filter['program_id__or__programs__id__in'] = filterPrograms.join(',')
    if (filterPrograms.length > 0) filter["program"] = filterPrograms.join(",")
    if (filterFormats.length > 0)
      filter["formats__id__in"] = filterFormats.join(",")
    cb(filter)
  }, [filterGoals, filterPrograms, filterFormats, year])

  const isMobile = useMediaQuery({ query: "(max-width: 650px)" })

  const russianFound = pluralize(articles.count, [
    "Найдена",
    "Найдено",
    "Найдено",
  ])
  const russianCount = pluralize(articles.count, [
    "публикация",
    "публикации",
    "публикаций",
  ])

  const yearFilter = (
    <div className={classes(css.filter, css.select)}>
      <SelectButton cb={setYear} value={year} />
    </div>
  )

  const goalsFilter = (
    <div className={css.filter}>
      <p className={css.filterTitle}>{t("Goals")}</p>
      <div className={css.listButtons}>
        {arrGoals.map(item => (
          <MenuButton
            checked={filterGoals.indexOf(item.id) > -1}
            key={item.id}
            cb={value => callbackGoals(item.id, value)}
          >
            {item.name}
          </MenuButton>
        ))}
      </div>
    </div>
  )

  const formatsFilter = (
    <div className={css.filter}>
      <p className={css.filterTitle}>{t("format")}</p>
      <div className={css.listButtons}>
        {formats.results.map(item => (
          <MenuButton
            checked={filterFormats.indexOf(item.id) > -1}
            key={item.id}
            cb={value => callbackFormats(item.id, value)}
          >
            {item.title}
          </MenuButton>
        ))}
      </div>
    </div>
  )

  const programsFilters = programsList.map(item => (
    <MenuButton
      checked={filterPrograms.includes(item.id)}
      className={css.button}
      key={item.id}
      cb={value => callbackPrograms(item.id, value)}
    >
      {item.title}
    </MenuButton>
  ))

  return (
    <div className={css.container}>
      <h1 className={css.title}>{contents.newsTitle}</h1>
      <div className={css.top} />
      {!isMobile && (
        <>
          <div className={css.filters}>
            <div className={css.left}>
              {goalsFilter}
              {formatsFilter}
            </div>
            {yearFilter}
          </div>
          {programsList.length > 0 && (
            <Accordion>
              <div className={css.filterButtons}>{programsFilters}</div>
            </Accordion>
          )}
        </>
      )}
      <PopupFilters>
        {yearFilter}
        {goalsFilter}
        {formatsFilter}
        <div className={css.filter}>
          <p className={css.filterTitle}>{t("programs")}</p>
          <div className={css.listButtons}>{programsFilters}</div>
        </div>
      </PopupFilters>
      <p className={[css.findNews]}>
        {language === "ru" ? russianFound : t("Found")} {articles.count}{" "}
        {language === "ru" ? russianCount : t("news_count")}
      </p>
    </div>
  )
}

export default Menu
